// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getMessaging,getToken, isSupported } from "firebase/messaging";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration 
// const firebaseConfig = {
//     apiKey: "AIzaSyB5Vlxg5xmqQpSl_urC_6VltaJtLV8JIPU",
//     authDomain: "uberlikeproject-d73c0.firebaseapp.com",
//     projectId: "uberlikeproject-d73c0",
//     storageBucket: "uberlikeproject-d73c0.appspot.com",
//     messagingSenderId: "653271860016",
//     appId: "1:653271860016:web:de63dac5533a97b1787ef5",
//     measurementId: "G-HNF3NW7EL1"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// // export const messaging = getMessaging(app);

// // Asynchronously initialize messaging only if supported
// let initializeMessaging = null;

// export const messaging = (async () => {
//     try {
//         const isSupportedBrowser = await isSupported();
//         if (isSupportedBrowser) {
//             initializeMessaging = getMessaging(app);
//             return initializeMessaging
//         }
//         console.log('Firebase not supported this browser');
//         return null;
//     } catch (err) {
//         console.log(err);
//         return null;
//     }
//     })();

// // export const generateToken = async () => {
// //     const permission = await Notification.requ console.error('Error initializing messaging:', eestPermission();
// //     console.log("permissionpermission",permission)

// //     if (permission === 'granted') {
// //         const token = await getToken(messaging,{
// //             vapidKey:"BCI4I5CO9KfhrbysxRosXBqlXnnWJPxZs97UZWHSXIVFk39YNGMCbFisro16HEtXu4YT_5J6g6Tnjk70WWHs0bY"
// //             })
// //         console.log('Notification permission granted Token.',token);
// //     } 
  
// // }



// // Function to request notification permissions and generate token
// export const generateToken = async () => {
//     try {
//         const permission = await Notification.requestPermission();

//         if (permission === 'granted') {
//             if (!initializeMessaging) {
//                 initializeMessaging = await messaging(); // Ensure messaging is initialized before use
//             }

//             if (initializeMessaging) {
//                 const token = await getToken(initializeMessaging, {
//                     vapidKey: "BCI4I5CO9KfhrbysxRosXBqlXnnWJPxZs97UZWHSXIVFk39YNGMCbFisro16HEtXu4YT_5J6g6Tnjk70WWHs0bY"
//                 });
//                 console.log('Notification permission granted. Token:', token);
//                 return token;
//             }
//         } else {
//             console.log('Notification permission denied.');
//         }
//     } catch (error) {
//         console.error('Error getting token:', error);
//     }
//     return null;
// };

// // vapidKey ::::== BFSzFqlVxfYDgoIF0XbHvXibH0-XhfViz53j7WmVcslnmJ3hK12lt73OShmPw5o4ftKuamVHVIvKKkYZ7bYtZy4 




// Import Firebase SDK functions
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB5Vlxg5xmqQpSl_urC_6VltaJtLV8JIPU",
    authDomain: "uberlikeproject-d73c0.firebaseapp.com",
    projectId: "uberlikeproject-d73c0",
    storageBucket: "uberlikeproject-d73c0.appspot.com",
    messagingSenderId: "653271860016",
    appId: "1:653271860016:web:de63dac5533a97b1787ef5",
    measurementId: "G-HNF3NW7EL1"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize messaging asynchronously, keeping function name `messaging`
let messagingInstance = null;

export const messaging = async () => {
    if (!messagingInstance) {
        try {
            const isSupportedBrowser = await isSupported();
            if (isSupportedBrowser) {
                messagingInstance = getMessaging(app);
                return messagingInstance;
            } else {
                console.log('Firebase Messaging is not supported in this browser.');
                return null;
            }
        } catch (err) {
            console.error('Error initializing messaging:', err);
            return null;
        }
    }
    return messagingInstance;
};

// Function to request notification permissions and generate token
export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            const messagingService = await messaging();  // Call the existing `messaging()` function
            if (messagingService) {
                const token = await getToken(messagingService, {
                    vapidKey: "BCI4I5CO9KfhrbysxRosXBqlXnnWJPxZs97UZWHSXIVFk39YNGMCbFisro16HEtXu4YT_5J6g6Tnjk70WWHs0bY"
                });
                console.log('Notification permission granted. Token:', token);
                return token;
            }
        } else {
            console.log('Notification permission denied.');
        }
    } catch (error) {
        console.error('Error getting token:', error);
    }
    return null;
};

