import React, { Suspense, Fragment, useContext, lazy, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import { AuthContext } from "src/context/Auth"; // Correct import
import AuthProvider from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import SettingsContext from "src/context/SettingsContext";
import toast, { Toaster } from "react-hot-toast";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import { generateToken, messaging } from "./Notifications/firebase";
import { onMessage } from "firebase/messaging";
import { IoMdNotifications } from "react-icons/io";
const history = createBrowserHistory();

function App() {
  const themeSeeting = useContext(SettingsContext);
  const [token,setToken] = useState("");
  const theme = createTheme({
    theme: themeSeeting.settings.theme,
  });


  const customToastStyles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '10px',
        backgroundColor: '#F56D21',
        color: 'black',
        borderRadius: '8px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
        width: '320px', 
        fontFamily: 'Arial, sans-serif',
        padding:"14px",
      },
    title: {
      fontWeight: 'bold',
      fontSize: '16px',
      marginBottom: '5px',
      display:"flex",
      alignItems: 'center', 
      gap:"5px"  
      // color: '#ffda79'
    },
    body: {
      fontSize: '14px',
      color: '#e0e0e0'
    }
  };
  
  const CustomToast = ({ title, body }) => (
    <div style={customToastStyles.container}>
      <div style={customToastStyles.title}><span><IoMdNotifications />{" "}</span><span>{title}</span></div>
      <div style={customToastStyles.body}>{body}</div>
    </div>
  );

  useEffect(() => {
  //  const token = await generateToken();
  const fetchToken = async () => {
    const tokens = await generateToken();
    console.log(tokens,"tokentoken")
    setToken(tokens);
};

    fetchToken();
    onMessage(messaging, (payload) => {
     const { title, body } = payload.notification;
     const audio = new Audio('/sound/notification.mp3');  // Add this line
        audio.play(); 
      toast.custom(<CustomToast title={title} body={body} />);
       console.log("payload", payload);
    });

    console.log(token,"tokentoken")
    
  },[])

  // useEffect(() => {
  //   // const unblock = history.block((location, action) => {
  //   //   if (action === 'POP') {
  //   //     // return 'Are you sure you want to go back?';
  //   //     sessionStorage.removeItem("adminToken");
  //   //     // sessionStorage.removeItem('sessionToken');

  //   //     // Optionally, you can show a custom alert or redirect the user
  //   //     // alert('Session expired. Please log in again.');

  //   //     // Redirect to the login page
  //   //     history.push('/');
        
  //   //     return ;
  //   //   }
  //   //   return true;
  //   // });

  //   const handleUnload = () => {
  //     // This triggers when the user removes the URL and presses Enter
  //     sessionStorage.removeItem("adminToken");
  // };

  // // Add event listener for unload event
  // window.addEventListener('beforeunload', handleUnload);

  // return () => {
  //     // unblock(); // Clean up the history block
  //     window.removeEventListener('beforeunload', handleUnload); // Clean up event listener
  // };

  //   // return () => {
  //   //   unblock();
  //   // };
  // }, [history]);

  return (
    <div className="App">
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
        theme={theme.palette.type}
      />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <AuthProvider>
              <RenderRoutes data={routes} />
            </AuthProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

//   function RenderRoutes(props) {

//       const userData = useContext(AuthContext);

//       const permissions = userData?.userData?.permissions;
//       console.log(permissions);

//  const subAdminRoutes = [
//   {
//     exact: true,
//     path: "/",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Login")),
//   },
//   {
//     exact: true,
//     path: "/",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Static/FaqSection.js")),
//   },

//   {
//     exact: true,
//     path: "/dashboard",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
//     ),
//   },

//   {
//     exact: true,
//     path: "/forget",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Forget")),
//   },

//   {
//     exact: true,
//     path: "/verify-otp",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
//   },

//   {
//     exact: true,
//     path: "/reset-password",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
//   },

//   //Driver List
//   {
//     exact: true,
//     path: "/driver-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Drivers")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/add-driver",
//         // guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/AddDrivers")
//         ),
//       },
//       {
//         exact: true,
//         path: "/driver-request",
//         layout: DashboardLayout,
//         guard: true,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/NewDriverList")
//         ),
//       },
//       {
//         exact: true,
//         path: "/driverrider-list",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/RiderHistory")
//         ),
//       },
//       {
//         exact: true,
//         path: "/drivergeneral-details",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/GeneralDetails")
//         ),
//       },

//       {
//         exact: true,
//         path: "/newdriver-details",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/NewDriverView")
//         ),
//       },
//     ]
//   },

//   //riderList

//   {
//     exact: true,
//     path: "/rider-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Riders/RiderListing")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/rider-history",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Riders/RiderHistory")
//         ),
//       },
//     ]

//   },

//   // Ride Screen all
//   {
//     exact: true,
//     path: "/ride-listall",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Rides/RidesList")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/viewRide-all",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Rides/ViewRideDetail")
//         ),
//       },
//     ]
//   },

//   //Vehicle Type & Fee Management
//   {
//     exact: true,
//     path: "/vehicle-manage",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/VehicleFeeManagement/VehicleFeeListing")
//     ),
//   },

//   //Earning Report
//   {
//     exact: true,
//     path: "/earning-report",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/EarningReport/EarningReport")
//     ),
//   },

//   // Promo Codes
//   {
//     exact: true,
//     path: "/promocode-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/PromoCodes/PromocodeList")
//     ),
//   },

//   // Review & Ratings
//   {
//     exact: true,
//     path: "/rider-review",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ReviewandRating/RiderReview")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/driver-review",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ReviewandRating/DriverReview")
//         ),
//       },
//     ]
//   },

//   // Report Management
//   {
//     exact: true,
//     path: "/report-management",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ReportManagement/ReportListing")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/view-report",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ReportManagement/ViewReport")
//         ),
//       },
//     ]
//   },

//   // Notification Management

//   {
//     exact: true,
//     path: "/notification",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Notification/index")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/Add-notification",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Notification/AddNotification")
//         ),
//       },
//     ]
//   },

//   //Chat Module
//   {
//     exact: true,
//     path: "/Chat-module",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ChatModule/index")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/View-chat",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ChatModule/ViewChat")
//         ),
//       },
//     ]
//   },

//   // SOS Screen
//   {
//     exact: true,
//     path: "/sos-alert",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/SosAlert/AlertListing")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/sosalert-detailList",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/SosAlert/DetailList")
//         ),
//       },
//     ]
//   },

//   {
//     exact: true,
//     path: "/content-management",
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ContentMangemet/ContentList")
//     ),
//     routes : [
//       {
//         exact: true,
//         path: "/view-content",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/ViewContent")
//         ),
//       },
//       {
//         exact: true,
//         path: "/view-contents",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/View")
//         ),
//       },
//       {
//         exact: true,
//         path: "/faqs",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/FaqContent")
//         ),
//       },

//       {
//         exact: true,
//         path: "/edit-content",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/EditContent")
//         ),
//       },
//     ]
//   },

//   {
//     exact: true,
//     guard: true,
//     path: "/profile",
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/Profile")),
//     routes  : [
//       {
//         exact: true,
//         path: "/change-password",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
//       },
//       {
//         exact: true,
//         guard: true,
//         path: "/edit-profile",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
//       },

//   {
//     exact: true,
//     guard: true,
//     path: "/bankAccount",
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/BankAccount")),
//   },
//     ]
//   },

//   //subAdmin start

//   {
//     exact: true,
//     guard: true,
//     path: "/sub-admin",
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/SubAdminListing")),
//     routes : [
//       {
//         exact: true,
//         guard: true,
//         path: "/add-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/AddSubAdmin")),
//       },

//       {
//         exact: true,
//         guard: true,
//         path: "/edit-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/EditSubAdmin")),
//       },

//       {
//         exact: true,
//         guard: true,
//         path: "/view-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/ViewSubAdmin")),
//       },

//     ]
//   },

//     //  //subAdmin end

//     {
//       exact: true,
//       guard: true,
//       path: "/veiw-admin",
//       layout: DashboardLayout,
//       component: lazy(() => import("src/views/pages/Dashboard/SubAdmin/VeiwAdmin")),
//     },

//   {
//     exact: true,
//     guard: true,
//     path: "/commission",
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/commission/CommissionList")),
//     routes : [
//       {
//         exact: true,
//         guard: true,
//         path: "/view-commission",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/commission/ViewCommission")),
//       },

//     ]
//   },

//   // {
//   //   exact: true,
//   //   guard: true,
//   //   path: "/edit-driver",
//   //   layout: DashboardLayout  ,
//   //   component: lazy(() => import("src/views/pages/Dashboard/Drivers/EditDrivers")),
//   // },
//   // /view-commission

//   {
//     exact: true,
//     path: "/404",
//     component: lazy(() => import("src/views/errors/NotFound")),
//   },

//   {
//     component: () => <Redirect to="/404" />,
//   },
// ];

//     return (
//       <Suspense fallback={<PageLoading />}>
//         <Switch>
//           {props.data.map((route, i) => {
//             const Component = route.component;
//             const Guard = route.guard ? AuthGuard : Fragment;
//             const Layout = route.layout || Fragment;

//             return (
//               <Route
//                 exact={route.exact}
//                 key={i}
//                 path={route.path}
//                 render={(props) => (
//                   <Guard>
//                     <Layout>
//                       {route.routes ? (
//                         <RenderRoutes data={route.routes} />
//                       ) : (
//                         <Component {...props} />
//                       )}
//                     </Layout>
//                   </Guard>
//                 )}
//               />
//             );
//           })}
//         </Switch>
//       </Suspense>
//     );
//   }

function RenderRoutes(props) {
  const userData = useContext(AuthContext);
  const permissions = userData?.userData?.permissions || {};

  const role = userData?.userData?.role;
  console.log("ashfasjfgahjfgash", userData, role);
  const isSubAdmin = Array.isArray(role) && role[0] === "subAdmin";

  const getRoutes = (routes) => {
    return routes.map((route, i) => {
      const Component = route.component;
      const Guard = route.guard ? AuthGuard : Fragment;
      const Layout = route.layout || Fragment;

      // Check if the route should be accessible to both roles
      const isAccessibleToBoth =
        !route.guard ||
        route.path === "/" ||
        route.path === "/dashboard" ||
        route.path === "/forget" ||
        route.path === "/verify-otp" ||
        route.path === "/profile" ||
        route.path === "/edit-profile" ||
        route.path === "/reset-password";

      // Check if the route requires specific permissions
      const hasPermission = isSubAdmin ? permissions[route.name] : true;

      console.table([
        hasPermission,
        isAccessibleToBoth,
        "ashfasjfgahjfgash",
        isSubAdmin,
      ]);

      if (!isAccessibleToBoth && !hasPermission) {
        return null; // Skip route if user doesn't have permission and is not accessible to both
      }

      return (
        <Route
          exact={route.exact}
          key={i}
          path={route.path}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? (
                  <RenderRoutes data={route.routes} />
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    });
  };

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {getRoutes(props.data)}
        <Route
          path="/404"
          component={lazy(() => import("src/views/errors/NotFound"))}
        />
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </Suspense>
  );
}
