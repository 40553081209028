import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection.js")),
  },

  //Driver List
  {
    name: "Driver",
    exact: true,
    path: "/driver-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Drivers")),
  },
  {
    name: "Driver",
    exact: true,
    path: "/add-driver",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/AddDrivers")
    ),
  },
  {
    name: "Driver",
    exact: true,
    path: "/driver-request",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/NewDriverList")
    ),
  },

  {
    name: "Driver",
    exact: true,
    path: "/view-edit-driver-request",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/EditDriverDocsRequest")
    ),
  },

  {
    name: "Driver",
    exact: true,
    path: "/viewDocsUpdateRequest",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/UpdateDocumentRequestView")
    ),
  },


  {
    name: "Driver",
    exact: true,
    path: "/edit-driver-request",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/EditDriverRequestsList")
    ),
  },
  {
    name: "Driver",
    exact: true,
    path: "/driverrider-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/RiderHistory")
    ),
  },
  {
    name: "Driver",
    exact: true,
    path: "/drivergeneral-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/GeneralDetails")
    ),
  },
  {
    name: "Driver",
    exact: true,
    path: "/newdriver-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/NewDriverView")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },
  {
    name: "Rider",
    exact: true,
    path: "/rider-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Riders/RiderListing")
    ),
  },
  {
    name: "Rider",
    exact: true,
    path: "/rider-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Riders/RiderHistory")
    ),
  },

  // Ride Screen all
  {
    name: "Rides",
    exact: true,
    path: "/ride-listall",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Rides/RidesList")),
  },
  {
    name: "Rides",
    exact: true,
    path: "/viewRide-all",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Rides/ViewRideDetail")
    ),
  },

  //Vehicle Type & Fee Management
  {
    name: "VehicleTypeFeeManagement",
    exact: true,
    path: "/vehicle-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/VehicleFeeManagement/VehicleFeeListing")
    ),
  },

  //Earning Report
  {
    name: "EarningReport",
    exact: true,
    path: "/earning-report",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EarningReport/EarningReport")
    ),
  },

  // Promo Codes
  {
    name: "PromoCodes",
    exact: true,
    path: "/promocode-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PromoCodes/PromocodeList")
    ),
  },

  // Review & Ratings
  {
    name: "ReviewRating",
    exact: true,
    path: "/rider-review",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReviewandRating/RiderReview")
    ),
  },
  {
    name: "ReviewRating",
    exact: true,
    path: "/driver-review",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReviewandRating/DriverReview")
    ),
  },

  // Report Management
  {
    name: "ReportManagement",
    exact: true,
    path: "/report-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReportManagement/ReportListing")
    ),
  },
  {
    name: "ReportManagement",
    exact: true,
    path: "/view-report",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReportManagement/ViewReport")
    ),
  },

  // Notification Management

  {
    name :"NotificationManagement",
    exact: true,
    path: "/notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Notification/index")
    ),
  },

  {
    name :"NotificationManagement",
    exact: true,
    path: "/Add-notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Notification/AddNotification")
    ),
  },

  //Chat Module
  {
    exact: true,
    path: "/Chat-module",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ChatModule/index")),
  },
  {
    exact: true,
    path: "/View-chat",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ChatModule/ViewChat")
    ),
  },

  // SOS Screen
  {
    name: "SOSAlert",
    exact: true,
    path: "/sos-alert",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SosAlert/AlertListing")
    ),
  },
  {
    name: "SOSAlert",
    exact: true,
    path: "/sosalert-detailList",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SosAlert/DetailList")
    ),
  },

  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },

  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    name: "StaticContentManagement",
    exact: true,
    guard: true,
    path: "/content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/ContentList")
    ),
  },

  {
    name: "StaticContentManagement",
    exact: true,
    path: "/view-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/ViewContent")
    ),
  },
  {
    name: "StaticContentManagement",
    exact: true,
    path: "/view-contents",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/View")
    ),
  },
  {
    name: "StaticContentManagement",
    exact: true,
    path: "/faqs",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/FaqContent")
    ),
  },

  {
    name: "StaticContentManagement",
    exact: true,
    path: "/edit-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentMangemet/EditContent")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },

  //subAdmin start

  {
    name: "SubAdminmanagement",
    exact: true,
    guard: true,
    path: "/sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/SubAdminListing")
    ),
  },

  {
    name: "SubAdminmanagement",
    exact: true,
    guard: true,
    path: "/add-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/AddSubAdmin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/veiw-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/VeiwAdmin")
    ),
  },

  {
    name: "SubAdminmanagement",
    exact: true,
    guard: true,
    path: "/edit-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/EditSubAdmin")
    ),
  },

  {
    name: "SubAdminmanagement",
    exact: true,
    guard: true,
    path: "/view-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/ViewSubAdmin")
    ),
  },

  //  //subAdmin end

  {
    name: "CommissionsManagement",
    exact: true,
    guard: true,
    path: "/commission",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/commission/CommissionList")
    ),
  },

  {
    name: "CommissionsManagement",
    exact: true,
    guard: true,
    path: "/viewCommissionData",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/commission/ViewCommissionData.js")
    ),
  },

  {
    name: "ContactAndSupport",
    exact: true,
    guard: true,
    path: "/viewHelpAndSupport",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/contactSupportManagement/ViewContactAndSupport.js")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/contactSupport",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/contactSupportManagement/ContactSupportListing")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/bankAccount",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BankAccount")),
  },

  {
    name:"CommissionsManagement",
    exact: true,
    guard: true,
    path: "/view-commission",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/commission/ViewCommission")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-driver",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drivers/EditDriverAndUpdateDocs")
    ),
  },
  // /view-commission

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];

// export const subAdminRoutes = [
//   {
//     exact: true,
//     path: "/",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Login")),
//   },
//   {
//     exact: true,
//     path: "/",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Static/FaqSection.js")),
//   },

//   {
//     exact: true,
//     path: "/dashboard",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
//     ),
//   },

//   {
//     exact: true,
//     path: "/forget",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Forget")),
//   },

//   {
//     exact: true,
//     path: "/verify-otp",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
//   },

//   {
//     exact: true,
//     path: "/reset-password",
//     layout: LoginLayout,
//     component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
//   },

//   //Driver List
//   {
//     name: "Driver",
//     exact: true,
//     path: "/driver-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/Drivers")),
//     routes: [
//       {
//         exact: true,
//         path: "/add-driver",
//         // guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/AddDrivers")
//         ),
//       },
//       {
//         exact: true,
//         path: "/driver-request",
//         layout: DashboardLayout,
//         guard: true,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/NewDriverList")
//         ),
//       },
//       {
//         exact: true,
//         path: "/driverrider-list",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/RiderHistory")
//         ),
//       },
//       {
//         exact: true,
//         path: "/drivergeneral-details",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/GeneralDetails")
//         ),
//       },

//       {
//         exact: true,
//         path: "/newdriver-details",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Drivers/NewDriverView")
//         ),
//       },
//     ],
//   },

//   //riderList

//   {
//     name: "Rider",
//     exact: true,
//     path: "/rider-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Riders/RiderListing")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/rider-history",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Riders/RiderHistory")
//         ),
//       },
//     ],
//   },

//   // Ride Screen all
//   {
//     name: "Rides",
//     exact: true,
//     path: "/ride-listall",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/Rides/RidesList")),
//     routes: [
//       {
//         exact: true,
//         path: "/viewRide-all",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Rides/ViewRideDetail")
//         ),
//       },
//     ],
//   },

//   //Vehicle Type & Fee Management
//   {
//     name: "VehicleTypeFeeManagement",
//     exact: true,
//     path: "/vehicle-manage",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/VehicleFeeManagement/VehicleFeeListing")
//     ),
//   },

//   //Earning Report
//   {
//     name: "EarningReport",
//     exact: true,
//     path: "/earning-report",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/EarningReport/EarningReport")
//     ),
//   },

//   // Promo Codes
//   {
//     name: "PromoCodes",
//     exact: true,
//     path: "/promocode-list",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/PromoCodes/PromocodeList")
//     ),
//   },

//   // Review & Ratings
//   {
//     name: "ReviewRating",
//     exact: true,
//     path: "/rider-review",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ReviewandRating/RiderReview")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/driver-review",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ReviewandRating/DriverReview")
//         ),
//       },
//     ],
//   },

//   // Report Management
//   {
//     name: "ReportManagement",
//     exact: true,
//     path: "/report-management",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ReportManagement/ReportListing")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/view-report",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ReportManagement/ViewReport")
//         ),
//       },
//     ],
//   },

//   // Notification Management

//   {
//     exact: true,
//     path: "/notification",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/Notification/index")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/Add-notification",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/Notification/AddNotification")
//         ),
//       },
//     ],
//   },

//   //Chat Module
//   {
//     exact: true,
//     path: "/Chat-module",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/ChatModule/index")),
//     routes: [
//       {
//         exact: true,
//         path: "/View-chat",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ChatModule/ViewChat")
//         ),
//       },
//     ],
//   },

//   // SOS Screen
//   {
//     name: "SOSAlert",
//     exact: true,
//     path: "/sos-alert",
//     guard: true,
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/SosAlert/AlertListing")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/sosalert-detailList",
//         guard: true,
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/SosAlert/DetailList")
//         ),
//       },
//     ],
//   },

//   {
//     name: "StaticContentManagement",
//     exact: true,
//     path: "/content-management",
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/ContentMangemet/ContentList")
//     ),
//     routes: [
//       {
//         exact: true,
//         path: "/view-content",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/ViewContent")
//         ),
//       },
//       {
//         exact: true,
//         path: "/view-contents",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/View")
//         ),
//       },
//       {
//         exact: true,
//         path: "/faqs",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/FaqContent")
//         ),
//       },

//       {
//         exact: true,
//         path: "/edit-content",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/ContentMangemet/EditContent")
//         ),
//       },
//     ],
//   },

//   {
//     exact: true,
//     guard: true,
//     path: "/profile",
//     layout: DashboardLayout,
//     component: lazy(() => import("src/views/pages/Dashboard/Profile")),
//     routes: [
//       {
//         exact: true,
//         path: "/change-password",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
//       },
//       {
//         exact: true,
//         guard: true,
//         path: "/edit-profile",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
//       },

//       {
//         exact: true,
//         guard: true,
//         path: "/bankAccount",
//         layout: DashboardLayout,
//         component: lazy(() => import("src/views/pages/Dashboard/BankAccount")),
//       },
//     ],
//   },

//   //subAdmin start

//   {
//     name: "SubAdminmanagement",
//     exact: true,
//     guard: true,
//     path: "/sub-admin",
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/SubAdmin/SubAdminListing")
//     ),
//     routes: [
//       {
//         exact: true,
//         guard: true,
//         path: "/add-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/SubAdmin/AddSubAdmin")
//         ),
//       },

//       {
//         exact: true,
//         guard: true,
//         path: "/edit-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/SubAdmin/EditSubAdmin")
//         ),
//       },

//       {
//         exact: true,
//         guard: true,
//         path: "/view-sub-admin",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/SubAdmin/ViewSubAdmin")
//         ),
//       },
//     ],
//   },

//   //  //subAdmin end

//   {
//     exact: true,
//     guard: true,
//     path: "/veiw-admin",
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/SubAdmin/VeiwAdmin")
//     ),
//   },

//   {
//     exact: true,
//     guard: true,
//     path: "/commission",
//     layout: DashboardLayout,
//     component: lazy(() =>
//       import("src/views/pages/Dashboard/commission/CommissionList")
//     ),
//     routes: [
//       {
//         exact: true,
//         guard: true,
//         path: "/view-commission",
//         layout: DashboardLayout,
//         component: lazy(() =>
//           import("src/views/pages/Dashboard/commission/ViewCommission")
//         ),
//       },
//     ],
//   },

//   // {
//   //   exact: true,
//   //   guard: true,
//   //   path: "/edit-driver",
//   //   layout: DashboardLayout  ,
//   //   component: lazy(() => import("src/views/pages/Dashboard/Drivers/EditDrivers")),
//   // },
//   // /view-commission

//   {
//     exact: true,
//     path: "/404",
//     component: lazy(() => import("src/views/errors/NotFound")),
//   },

//   {
//     component: () => <Redirect to="/404" />,
//   },
// ];
