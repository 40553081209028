// import React, { createContext, useState, useEffect } from "react";
// import axios from "axios";
// import { calculateTimeLeft } from "src/utils";
// import { getDataHandlerWithToken } from "src/apiconfig/service";
// import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import toast from "react-hot-toast";
// import { Route, Redirect } from "react-router-dom";
// import { io } from "socket.io-client";
// import apiconfig from "src/apiconfig/apiconfig";
// export const AuthContext = createContext();

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem("creatturAccessToken", accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem("creatturAccessToken");
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

// function checkLogin(token) {
//   const accessToken = window.sessionStorage.getItem("adminToken")
//     ? window.sessionStorage.getItem("adminToken")
//     : token;

//   return accessToken ? true : false;
// }

// export default function AuthProvider(props) {
//   const [isLogin, setIsLogin] = useState(checkLogin());

//   const [userData, setUserData] = useState({});
//   const [endTime, setEndtime] = useState();
//   const [socket, setSocket] = useState("");
//   const [timeLeft, setTimeLeft] = useState();
//   const [isLoading, setIsLoading] = useState(true);
//   const [pageTitle, setPageTitle] = useState("");
//   const token = sessionStorage.getItem("adminToken");
//   const history = useHistory();
//   const location = useLocation();
//   const [currentPath1, setCurrentPath1] = useState(location.pathname);
//   const [previousPath, setPreviousPath] = useState("");

//   useEffect(() => {
//     localStorage.setItem("pathname", currentPath1);
//     setCurrentPath1(
//       location.pathname == "/" || location.pathname == "/404"
//         ? "/dashboard"
//         : location.pathname
//     ); // Update the current path
//   }, [location]);

//   const handleTitle = (values) => {
//     setPageTitle(values);
//   };
//   const excludedPaths = [
//     "/",
//     "/verify-otp",
//     "/reset-password",
//     "/forget",
//     "/401",
//   ];

//   const currentPath = window.location.pathname;
//   const isExcludedPath = excludedPaths.includes(currentPath);
//   useEffect(() => {
//     getProfileData();
//   }, [history.location.pathname]);
//   // useEffect(() => {
//   //   if (isExcludedPath) {
//   //     localStorage.clear();
//   //     sessionStorage.clear();
//   //   }

//   // }, [isExcludedPath]);

//   const getProfileData = async () => {
//     if (
//       window.location.pathname === "/forget" ||
//       window.location.pathname === "/verify-otp" ||
//       window.location.pathname === "/reset-password"
//     ) {
//       console.log("");
//     } else {
//       try {
//         const res = await getDataHandlerWithToken("getProfile");
//         if (res?.data?.responseCode === 200) {
//           setUserData(res?.data?.adminDetails);
//           setIsLoading(false);
//           if (isExcludedPath) {
//             history.push(localStorage.getItem("pathname"));
//           }
//         } else if (res.data.message == "Invalid Token") {
//           history.push("/");
//           if (window.location.pathname === "/") {
//             console.log("");
//           } else {
//             localStorage.clear();
//             sessionStorage.clear();
//           }
//         } else {
//           setIsLoading(false);
//         }
//       } catch (error) {
//         setIsLoading(false);
//       }
//     }
//   };

//   useEffect(() => {
//     if (sessionStorage.getItem("adminToken")) getProfileData();
//     if (token) {
//       setUserData(token);
//     }
//   }, [sessionStorage.getItem("adminToken")]);

//   useEffect(() => {
//     if (endTime) {
//       const timer = setTimeout(() => {
//         setTimeLeft(calculateTimeLeft(endTime));
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   });
//   useEffect(() => {
//     const newSocket = io(apiconfig.url, {
//       transports: ["polling"],
//       auth: { _id: userData?._id },
//     });
//     setSocket(newSocket);

//     console.log("socketsocketsocketsocketsocket", socket);
//   }, []);

//   let data = {
//     userLoggedIn: isLogin,
//     userData,
//     setEndtime,
//     isLoading,
//     timeLeft,
//     pageTitle,
//     socket,
//     handleTitle: (values) => {
//       handleTitle(values);
//     },
//     userLogIn: (type, data) => {
//       setSession(data);
//       setIsLogin(type);
//     },
//     setIsLogin,
//     getProfileData: () => {
//       getProfileData();
//     },
//     checkLogin: (token) => {
//       checkLogin(token);
//     },
//   };

//   return (
//     <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
//   );
// }

import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getDataHandlerWithToken } from "src/apiconfig/service";
import { useHistory, useLocation, Route, Redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import apiconfig from "src/apiconfig/apiconfig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.sessionStorage.getItem("adminToken")
    ? window.sessionStorage.getItem("adminToken")
    : token;

  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [socket, setSocket] = useState(null);
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("");
  const token = sessionStorage.getItem("adminToken");
  const history = useHistory();
  const location = useLocation();
  const [currentPath1, setCurrentPath1] = useState(location.pathname);
  const [previousPath, setPreviousPath] = useState("");
  const [allOnline, setAllOnline] = useState([]);

  useEffect(() => {
    localStorage.setItem("pathname", currentPath1);
    setCurrentPath1(
      location.pathname === "/" || location.pathname === "/404"
        ? "/dashboard"
        : location.pathname
    );
  }, [location]);

  const handleTitle = (values) => {
    setPageTitle(values);
  };

  const excludedPaths = [
    "/",
    "/verify-otp",
    "/reset-password",
    "/forget",
    "/401",
  ];

  const currentPath = window.location.pathname;
  const isExcludedPath = excludedPaths.includes(currentPath);

  useEffect(() => {
    getProfileData();
  }, [history.location.pathname]);

  const getProfileData = async () => {
    if (
      window.location.pathname === "/forget" ||
      window.location.pathname === "/verify-otp" ||
      window.location.pathname === "/reset-password"
    ) {
      console.log("");
    } else {
      try {
        const res = await getDataHandlerWithToken("getProfile");
        // console.log("dagadgajhafRes",res);
        if (res?.responseCode === 200) {
          setUserData(res?.adminDetails);
          setIsLoading(false);
          if (isExcludedPath) {
            history.push(localStorage.getItem("pathname"));
          }
        } else if (res?.message === "Invalid Token") {
          history.push("/");
          if (window.location.pathname === "/") {
            console.log("");
          } else {
            localStorage.clear();
            sessionStorage.clear();
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("adminToken")) getProfileData();
    if (token) {
      setUserData(token);
    }
  }, [sessionStorage.getItem("adminToken")]);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  // useEffect(() => {
  //   if (userData?._id) {
  //     const newSocket = io(apiconfig.url, {
  //       transports: ["polling"],
  //       auth: { _id: userData._id },
  //     });
  //     setSocket(newSocket);
  //     newSocket.on("onlineUser", (data) => {
  //       let allOnline = [];
  //       for (let i of data) {
  //         allOnline.push(i._id);
  //       }
  //       setAllOnline(allOnline);
  //     });
  //   }
  // }, [userData?._id]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    isLoading,
    timeLeft,
    pageTitle,
    socket,
    allOnline,
    handleTitle: (values) => {
      handleTitle(values);
    },
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    setIsLogin,
    getProfileData: () => {
      getProfileData();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
